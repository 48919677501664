/* eslint-disable react/prop-types */
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AOS from 'aos';
import PropTypes from 'prop-types';
import 'aos/dist/aos.css';
import LandingPageCampaigns from 'pages/landing-page-campaigns';
import LandingPageLive from 'pages/landing-page-live';
import LandingPageInstitutional from 'pages/landing-page-institutional';
import LandingPageMgm from 'pages/landing-page-mgm';
import Home from './pages/home';
import ParaEmpresas from './pages/para-empresas';
import ParaColaboradores from './pages/para-colaboradores';
import FaqPage from './pages/faq';
import AboutUs from './pages/sobre-nos';
import Header from './components/header';
import Footer from './components/footer';
import ScrollToTop from './components/scroll-top';
import ImgShare from './images/img_share.jpg';
import PrivacyPolicy from './pages/politica-privacidade';

AOS.init({ once: true, duration: 1000 });

const redirectTo = (url) => {
  window.location.href = url + window.location.search;
};

const MainLayout = ({ children }) => (
  <>
    <Header />
    <ScrollToTop />
    {children}
    <Footer />
  </>
);

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

const App = () => (
  <HelmetProvider>
    <Helmet htmlAttributes={{ lang: 'pt-br' }}>
      <meta charSet="utf-8" />
      <title>Ali - Liberdade Financeira</title>
      <meta name="title" content="Você no controle do seu dinheiro" />
      <meta
        name="description"
        content="Em parceria com as empresas oferecemos soluções de bem-estar financeiro para os colaboradores."
      />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://alicredito.com.br/" />
      <meta property="og:title" content="Você no controle do seu dinheiro" />
      <meta
        property="og:description"
        content="Em parceria com as empresas oferecemos soluções de bem-estar financeiro para os colaboradores."
      />
      <meta property="og:image" content={ImgShare} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://alicredito.com.br/" />
      <meta
        property="twitter:title"
        content="Você no controle do seu dinheiro"
      />
      <meta
        property="twitter:description"
        content="Em parceria com as empresas oferecemos soluções de bem-estar financeiro para os colaboradores."
      />
      <meta property="twitter:image" content={ImgShare} />
    </Helmet>
    <Router>
      <Switch>
        <Route path="/para-empresas" exact>
          <MainLayout>
            <ParaEmpresas />
          </MainLayout>
        </Route>
        <Route path="/para-colaboradores" exact>
          <MainLayout>
            <ParaColaboradores />
          </MainLayout>
        </Route>
        <Route path="/duvidas-frequentes" exact>
          <MainLayout>
            <FaqPage />
          </MainLayout>
        </Route>
        <Route path="/sobre-nos" exact>
          <MainLayout>
            <AboutUs />
          </MainLayout>
        </Route>
        <Route path="/bluefriday" exact>
          {() => redirectTo(`${process.env.REACT_APP_BLUEFRIDAY_URL}`)}
        </Route>
        <Route path="/2023noazul" exact>
          {() => redirectTo(`${process.env.REACT_APP_ANO_NOVO_URL}`)}
        </Route>
        <Route path="/blog" exact>
          {() => redirectTo(`${process.env.REACT_APP_BLOG_URL}`)}
        </Route>
        <Route path="/blog/post/:slug" exact>
          {() => redirectTo(`${process.env.REACT_APP_BLOG_URL}`)}
        </Route>
        <Route path="/blog/:category" exact>
          {() => redirectTo(`${process.env.REACT_APP_BLOG_URL}`)}
        </Route>
        <Route path="/site-novo" exact>
          {() => redirectTo(`${process.env.REACT_APP_SITE}`)}
        </Route>
        <Route path="/politica-privacidade" exact>
          <MainLayout>
            <PrivacyPolicy />
          </MainLayout>
        </Route>
        <Route path="/" exact>
          <MainLayout>
            <Home />
          </MainLayout>
        </Route>
        <Route path="/live/:companyLive">
          <LandingPageLive />
        </Route>
        <Route path="/formulario/:company">
          <LandingPageCampaigns />
        </Route>
        <Route path="/alivie-o-bolso/:companyMgm/">
          <LandingPageMgm />
        </Route>
        <Route path="/:institutional">
          <LandingPageInstitutional />
        </Route>
      </Switch>
    </Router>
  </HelmetProvider>
);

export default App;
